import React, { useContext } from "react";
import { useNavigate } from "react-router";
// import "../comp/Subscription.css";
import Context from "../../Context/Context";
import HappyprancerPaypalMonthly from "../Subscription/LissomePaypalMonthly.js";
import HappyprancerRazorpayMonthly from "../Subscription/LissomeRazorpayMonthly";

export default function Subscription() {
    const Ctx = useContext(Context);
    const UserCtx = useContext(Context).userData;

    const Navigate = useNavigate();
    console.log(Ctx.productList)

    return (
        <>
            <div className="Back text-[1rem]  flex  flex-col items-center h-auto max980:h-[auto] justify-center gap-[5rem] pb-20 bg-[#f5f5f5]">
                <div className="text-center mt-20 RussoOne ">
                    <h1>CHECK OUT OUR EXECLUSIVE OFFER!</h1>
                    <h3 className="text-[1rem]">see what are the pricing in details</h3>
                </div>
                <ul className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 max450:pl-0">
                    {Ctx.productList.map((item) => {
                        return (
                            <li key={item.productId} className="bg-white w-[24rem] h-[46rem]  rounded-[2rem] z-0  flex flex-col items-center gap-4 shadowSubscribe max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto p-12 border-[#C4306B] border-[0.1rem]">
                                <p className="text-[1.6rem]">{item.heading}</p>
                                <ul className=" text-[1rem] h-auto pl-0 flex flex-col">
                                    {item.provides.map((i) => {
                                        return (
                                            <li >
                                                <p>{i}</p>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <h1 className="text-left w-[110%]">
                                    {(item.currency === "INR" ? "₹ " : "$ ") +
                                        parseInt(item.amount) / 100 +
                                        "/" +
                                        item.durationText}
                                </h1>
                                {Ctx.isAuth ? (
                                    <div>
                                        {UserCtx.status === "Active" ? (
                                            <p className="text-[1rem] w-[18rem] px-5 py-1 rounded-2xl text-[#C4306B] bg-white border-[#C4306B] border-[0.2rem] h-[3rem] flex justify-center items-center mt-[auto] max450:w-[60vw]">
                                                Already Subscribed
                                            </p>
                                        ) : (
                                            <>
                                                {item.currency === "INR"
                                                    ? item.durationText === "Month" && (
                                                        <HappyprancerRazorpayMonthly />
                                                    )
                                                    : item.durationText === "Month" && (
                                                        <HappyprancerPaypalMonthly />
                                                    )}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <button
                                        onClick={() => {
                                            Navigate("/signup");
                                        }}
                                        className="w-[15rem] bg-[#C4306B] text-[#ffff] px-12 py-2 rounded-2xl hover:text-[#C4306B] hover:bg-white hover:border-[#C4306B] hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-[auto] max450:w-[60vw]"
                                    >
                                        Sign Up
                                    </button>
                                )}
                            </li>
                        );
                    })}
                </ul>
                
            </div>
        </>
    );
}

