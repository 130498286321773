import React, { useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import Context from '../Context/Context'
import { useLocation } from 'react-router-dom'
import './nav.css'
import InstitutionContext from '../Context/InstitutionContext'

const NavBar = () => {
  const InstitutionData = useContext(InstitutionContext).institutionData
  const [isNavActive, setIsNavActive] = useState(false)
  const UserCtx = useContext(Context)
  const Navigate = useNavigate()
  const location = useLocation()
  const { checkSubscriptionStatus } = useContext(Context)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [activePath, setActivePath] = useState(location.pathname)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const navBarContent = [
    { label: 'ABOUT US', path: '/aboutus' },
    { label: 'INSTRUCTOR', path: '/instructor' },
    { label: 'SETTINGS', path: '/settings' },
    { label: 'GALLERY', path: '/gallery' },
    { label: 'SCHEDULE', path: '/schedule' }
  ]

  const getInitials = (name) => {
    if (!name) return '';
    const initials = name
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase())
      .join('');
    return initials;
  };

  const handleMenuItemClick = (path) => {
    Navigate(path)
    setActivePath(path)
    setIsNavActive(false)
  }

  return (
    <div
      className={`fixed h-8 mb-[5rem] z-[1000] ${location.pathname === '/dashboard' ? 'max536:h-[5rem]  max536:mt-0' : ''
        } `}
    >
      <div
        className={
          'w-screen z-[1000] h-[2rem] flex justify-end items-center gap-4 pr-[3rem] py-3 pb-[2rem]'
        }
        style={{
          backgroundColor: InstitutionData.PrimaryColor
        }}
      >
        {UserCtx.isAuth ? (
          <div className={'flex flex-row items-center justify-center gap-4'}>
            <button
              className={'text-white'}
              onClick={() => {
                Navigate('/dashboard')
              }}
            >
              Welcome, {UserCtx.userData.userName.split(' ')[0]}
            </button>
            <button
              className={'text-white'}
              onClick={() => {
                Navigate('/dashboard')
              }}
            >
              Dashboard
            </button>
            <div
              onClick={() => {
                Navigate('/dashboard')
              }}
              style={{ borderColor: checkSubscriptionStatus.borderColor }}
              className={`flex items-center justify-center w-[1.8rem] h-[1.8rem] border-[2.5px] rounded-[50%] cursor-pointer ${location.pathname === '/dashboard' ? 'max536:hidden' : ''
                }`}
            >
              {UserCtx.userData.imgUrl ? (
                <img
                  key={'profileUpdate3' + Date.now()}
                  src={UserCtx.userData.imgUrl}
                  alt="Profile"
                  className="w-100 h-100 rounded-full cursor-pointer"
                />
              ) : (
                <div
                  className="w-full h-full rounded-full flex items-center justify-center text-white bg-gray-800"
                >
                  {getInitials(UserCtx.userData.userName)}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex gap-4">
            <button
              className={`text-stone-300 text-md font-bold hover:text-stone-100 ${activePath === '/login' ? 'text-white' : ''}`}
              onClick={() => {
                Navigate('/login')
              }}
            >
              Login
            </button>
            <button
              className={`text-stone-300 text-md font-bold hover:text-stone-100 ${activePath === '/signup' ? 'text-white' : ''}`}
              onClick={() => {
                Navigate('/signup')
              }}
            >
              Join Now
            </button>
          </div>
        )}
      </div>
      <div
        className={`flex z-[1000] fixed items-center justify-between text-stone-600 w-screen bg-white border-b  h-[3.8rem] px-10 left-0 max536:bg-black`}
        style={{
          borderColor: InstitutionData.PrimaryColor
        }}
      >
        <Link
          to="/"
          className={`logo bg-white w-[9rem] h-[3.4rem] rounded-xl flex items-center justify-center max536:w-[5rem] ${location.pathname === '/dashboard' ? 'max536:hidden' : ''
            }`}
        >
          <img
            className={`relative rounded-301xl w-full p-1 h-[2.5rem] shrink-0 object-contain`}
            alt=""
            src={InstitutionData.logoUrl}
          />
        </Link>
        <ul className={`flex gap-6 max800:hidden font-sans-sarif mt-[2rem]`}>
          {navBarContent.map((item) =>
            windowWidth >= 536 && item.label === 'SETTINGS' ? null : (
              <li
                key={item.path}
                className={`flex font-semibold items-center justify-center hover:text-primaryColor`}
              >
                <p
                  className={`cursor-pointer ${activePath === item.path ? 'text-primaryColor' : ''}`}
                  onClick={() => handleMenuItemClick(item.path)}
                >
                  {item.label}
                </p>
              </li>
            )
          )}
        </ul>
        <div
          className={`relative min800:hidden max800:block w-[auto] ${location.pathname === '/dashboard' ? 'max536:hidden' : ''
            }`}
          onClick={() => {
            setIsNavActive(!isNavActive)
          }}
        >
          <AiOutlineMenu color={'black'} />
          {isNavActive && (
            <ul
              className={`bg-black shadow-lg w-[10rem] absolute top-[1.6rem] right-0 z-40`}
            >
              {navBarContent.map((item) =>
                windowWidth >= 536 && item.label === 'SETTINGS' ? null : (
                  <li
                    key={item.path}
                    className={`flex items-center justify-center h-10`}
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    {item.label}
                  </li>
                )
              )}
              {UserCtx.isAuth ? (
                <li
                  className={`flex items-center justify-center h-10 overflow-hidden   hover:bg-primaryColor `}
                  onClick={() => handleMenuItemClick('/dashboard')}
                >
                  {UserCtx.userData.userName.split(' ')[0]}
                </li>
              ) : (
                <li
                  className={`flex items-center justify-center h-10  hover:bg-primaryColor `}
                  onClick={() => handleMenuItemClick('/login')}
                >
                  Login
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          className={`min536:hidden max536:fixed top-0 left-0 z-40 bg-black`}
        >
          {isNavActive ? (
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/images/NavBar/cross.svg`}
              alt=""
              className={` fixed top-10 right-6 z-60 cursor-pointer h-8`}
              style={{
                backgroundColor: InstitutionData.PrimaryColor
              }}
              onClick={() => {
                setIsNavActive(!isNavActive)
              }}
            />
          ) : (
            <img
              src={`https://institution-utils.s3.amazonaws.com/institution-common/images/NavBar/Menu.svg`}
              alt=""
              className={`${location.pathname !== '/dashboard'
                ? 'max536:hidden max536:bg-white'
                : ''
                } fixed top-2 right-4 z-60 cursor-pointer h-4 bg-transparent`}
              onClick={() => {
                setIsNavActive(!isNavActive)
              }}
            />
          )}
          {isNavActive && (
            <div
              className={`z-[1000]  h-screen w-screen text-white sans-sarif text-[1.8rem]`}
              style={{
                backgroundColor: InstitutionData.PrimaryColor
              }}
            >
              <ul className={`pt-24 flex flex-col items-start px-24 gap-8`}>
                {navBarContent.map((item) =>
                  windowWidth >= 536 && item.label === 'SETTINGS' ? null : (
                    <li
                      key={item.path}
                      className={`flex items-center justify-center cursor-pointer text-white`}
                      onClick={() => handleMenuItemClick(item.path)}
                    >
                      {item.label}
                    </li>
                  )
                )}
                {UserCtx.isAuth ? (
                  <li
                    className={`flex h-10 cursor-pointer border-b border-white overflow-x-hidden w-[60vw]`}
                    onClick={() => handleMenuItemClick('/dashboard')}
                  >
                    {UserCtx.userData.userName.split(' ')[0]}
                  </li>
                ) : (
                  <li
                    className={`flex items-center justify-center h-10 cursor-pointer border-b border-white`}
                    onClick={() => handleMenuItemClick('/login')}
                  >
                    Login
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavBar
